import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-form-adcard',
  templateUrl: './form-adcard.component.html',
  styleUrls: ['./form-adcard.component.css']
})
export class FormAdcardComponent implements OnInit {
  @Input() formStructure: any;
  @Output() formValue = new EventEmitter();

  public isFormReady: boolean = false;
  public formRows: Array<FormCardIpRow> = [];
  public enInputType = InputType;
  public errorTimer: any;
  public isErrorShown: boolean = false;


  parseAndInit(rawAdCard) {
    const cardBody: Array<any> = rawAdCard.content.body;
    let ipRowLabel: string, ipControlData: any, ipRowInfo: string,
      ipControlType: InputType, ipControlName: string;

    // Loop through the object and parse into appropriate structures.
    for (let i in cardBody) {
      const item = cardBody[i];

      switch (item.type) {
        case "TextBlock":
          // Label or Info
          if (item.size && item.size == 'small') {
            ipRowInfo = item.text;
          } else {
            if (parseInt(i) != 0) {
              // Push into array
              this.formRows.push(new FormCardIpRow(ipRowLabel, ipControlType,
                ipControlName, ipControlData, ipRowInfo));
              // Reset variables
              ipRowLabel = ipControlType = ipControlName = ipControlData = ipRowInfo = null;
            }
            ipRowLabel = item.text;
          }
          break;

        case "Input.Text":
          ipControlType = InputType.TextBox;
          ipControlName = item.id;
          break;

        case "Input.ChoiceSet":
          ipControlType = InputType.Select;
          ipControlName = item.id;
          ipControlData = item;
          break;

        default:
          break;
      }
    }

    // Push the last row into array
    this.formRows.push(new FormCardIpRow(ipRowLabel, ipControlType,
    ipControlName, ipControlData, ipRowInfo));

    // Activate form
    this.isFormReady = true;
  }


  private isAllFieldsFilled(formData){
    for( let keys in formData ){
      if( formData[keys] == '' ){
        return false;
      }
    }
    return true;
  }


  submitForm(formData) {
    if(this.isAllFieldsFilled(formData)){
      this.formValue.emit(formData);
    }else{
      this.showError();
    }
  }


  // TODO: Handle form re-send!!
  showError(){
    clearTimeout(this.errorTimer);
    this.isErrorShown = true;

    this.errorTimer = setTimeout(() => {
      this.isErrorShown = false;
    }, 10000);
  }


  constructor() { }


  ngOnInit() {
    this.parseAndInit(this.formStructure);
  }

}


export class FormCardIpRow {
  public ddOptions: Array<DdOptionModel>;

  populateOptions(data) {
    const choices = data.choices;
    this.ddOptions = [];
    // Push the options into array.
    for (let i in choices) {
      this.ddOptions.push(new DdOptionModel(choices[i].title, choices[i].value));
    }
  }

  constructor(
    public controlLabel: string,
    public controlType: InputType,
    public controlName: string,
    public controlData: any,
    public controlInfo: string,
  ) {
    if (controlType == InputType.Select) {
      this.populateOptions(controlData);
    }
  };
}


export class DdOptionModel {
  constructor(
    public title: string,
    public value: string,
  ) { }
}


export enum InputType {
  TextBox,
  Select,
  TextArea,
}