/*-------------------------------------------------------*/
/* KP - dashboard-home component replacement */
/*-------------------------------------------------------*/

import { Component, OnInit, OnDestroy } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import { DirectLine, CardAction, IBotConnection, User, ConnectionStatus, Activity } from '../services/botframework-directlinejs';
import { SharedService } from '../services/sharedService';
import { DirectLineService } from '../services/directLineService';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ChatMessageModel, MsgType, MsgAuthor } from '../models/chat-message-model';
import { NewTktCardModel, TicketType } from '../models/new-tkt-card-model';
import { TktStatusCardModel } from '../models/tkt-status-card-model';
import { InfoCardModel } from '../models/info-card-model';
import { isUndefined } from 'util';
import { chatBubbleAnimation, botAvatarAnimation, cardItemAnimation, userAvatarAnimation, cardBtnAnimation } from '../utils/chat-animation'
import { FormattedTextModel } from '../models/formatted-text-model';
import { ChoiceCardModel } from '../models/choice-card-model';
import { ActionCardModel } from '../models/action-card-model';
import { OutageCardModel } from '../models/outage-card-model';
import { FormAdcardComponent } from '../form-adcard/form-adcard.component';
import { NewTktCardModelAD, TicketTypeAD } from '../models/new-tkt-card-model-AD';
import { AppStateService } from '../services/app-state.service';
import { DecoratedText, TextDecorationType } from "../models/DecoratedText";
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';
import { Subscription } from 'rxjs';
import { I18nService } from '../services/i18n.service';
import { MultiContentCardModel, ContentType } from '../models/multi-content-card-model';
import * as AdaptiveCards from 'adaptivecards';
import {Action,SubmitAction} from "adaptivecards"

let self: ChatBoxComponent;

@Component({
  selector: 'app-chat-box',
  templateUrl: './chat-box.component.html',
  styleUrls: ['./chat-box.component.css'],
  animations: [chatBubbleAnimation, botAvatarAnimation, userAvatarAnimation, cardItemAnimation, cardBtnAnimation],
})
export class ChatBoxComponent implements OnInit, OnDestroy {

  public conversation: Array<ChatMessageModel> = [];
  private _conversationCustomIdLedger: Array<number> = [];
  private ackPendingIdxList: Array<number> = [];
  private reactPendingIdxList: Array<number> = [];
  private isCollectingInfoArticles: boolean = false;
  private _currConnState: ConnectionStatus;
  private subscription: Subscription = new Subscription();
  public shouldConnectSocket: boolean = false;

  // Enums have been re-declared to be able to referenced in templates.
  public enMsgType = MsgType;
  public enMsgAuthor = MsgAuthor;
  public enTextDecorationType = TextDecorationType;
  public enMconTextType = ContentType;
  private _timerTokenRefresh;
  public i18nTexts: any;

  // The below three variables should only be set by the method showModalMsg()
  public _shouldShowModalMsg: boolean = false;
  public _modalMsgBody: string;
  public _modalMsgType: number;
  public _isTxtDisabled: boolean = false;
  private _scrollTimer: any;
  private _micTimer: any;

  public readonly statusCardDefaultCount: number = 3;
  public readonly infoCardDefaultCount: number = 3;
  public readonly outageCardDefaultCount: number = 3;

  /*-------------------------------------------------------*/
  /* Voice Recognition implementation */
  /*-------------------------------------------------------*/
  public isListening: boolean = false;
  public SpeechSDK;
  public reco: SpeechSDK.SpeechRecognizer;
  public voiceSubscriptionDetails: any;

  // directLineObj: DirectLine;
  textToSpeechmsg:any
  directLineObj: any;
  msgToBot: string;
  userName: string;
  authObj: any;
  email: any;
  geoLocationObj: any = { 'country': 'Netherlands', 'city': 'Amsterdam' };
  directLineToken: string;
  jwtToken: string = '';
  sysId: string = '';
  sendCalDate: boolean = true;
  CurrentFile: any;
  ImageSource: any

  memberID:any
  convID:any
  jwtFromGenesys:any
  wssUrl:any
  subscriptionFromAgent : Subscription
  alertFromAgentLeave : Subscription
  alertFromAgentJoin : Subscription
  alertFromAgentDetails : Subscription
  messageFromAgent :any
  tempMsg:any

  isAdaptiveCard:boolean = false
  renderedAdCard:any = ''

  RenderAdaptiveCard(cardData){
    let card=cardData;
    let adaptiveCard = new AdaptiveCards.AdaptiveCard();
    adaptiveCard.hostConfig = new AdaptiveCards.HostConfig({ fontFamily: 'Segoe UI, Helvetica Neue, sans-serif'  });
     adaptiveCard.parse(card); 
    adaptiveCard.onExecuteAction = function(action:Action) {
       if (action instanceof SubmitAction) {
        this.directLineObj.postActivity({
          from: { id: self.userName },
          type: 'message',
          value: JSON.stringify(action.data)
      }).subscribe(obs => {
          console.log("form success");
      });
      console.log(JSON.stringify(action.data));
      
      }
     };
   
    this.renderedAdCard = adaptiveCard.render();
    //document.getElementById('Adcardtmpl').appendChild(renderedCard);
  }

  

  fetchParamsData(params: any) {
    let data = atob(params['data'] || '');
    if (data !== '') {
      self.authObj = JSON.parse(data);

      // Set usernames
      // self.userName = self.authObj.bot_user.substring(0, self.authObj.bot_user.lastIndexOf("@"));
      self.userName = self.authObj.bot_user;
      this._appStateService.username = self.userName;
      self.email = self.authObj.email;

      // Set tokens
      self.directLineToken = self.authObj.directLineToken;
      self.jwtToken = self.authObj.jwtToken;
    }
    else {
      this.router.navigate(['/login']);
    }
  }


  subscribeMessages() {
    // Subscribe to all activities
    this.subscription.add(
      this.directLineObj.activity$
        .subscribe(data => {
          console.log(" ** ACTIVITY:", data);
        })
    );

    // Handle Typing event
    this.subscription.add(
      this.directLineObj.activity$
        .filter(activity => {
          return (activity.type === 'typing');
        })
        .subscribe(message => {
          console.log("Identified typing event!!");
          this._appStateService.isActionMsgShown = true;
        })
    );

    // Handle messages from the USER.
    this.subscription.add(
      this.directLineObj.activity$
        .filter(activity => {
          return (activity.type === 'message') && (activity.from.id === self.userName);
        })
        .subscribe(message => {
          console.log("User msg: ", message);
          let msgId: number = parseInt(message.id.split('|')[1]);
          let customId: number = message.channelData.customUserId;
          
          let convo: string = message.from.id + ": " + message.text + "\n\n";
          this._appStateService.pushMessageToHistory(convo);

          // TODO: Properly handle out-of-order messages.
          if (this._conversationCustomIdLedger.includes(customId)) {
            // The message is a fresh one. Acknowledge it!
            this.acknowledgeUserMessage(message);
          } else {
            // The message is from history.
            let newMsg: ChatMessageModel = new ChatMessageModel(MsgAuthor.User, message.text, MsgType.TextOnly, new Date(message.timestamp), false);
            this.addMsgToConversation(newMsg);
          }
        })
    );

    // Handle messages from the BOT.
    
    this.subscription.add(
      this.directLineObj.activity$
        .filter(activity => {
          return activity.type === 'message' && (activity.from.id !== self.userName);
        })
        .subscribe(message => {
          console.log("Bot msg: ", message);
          let currMsgBody: any;
          let currMsgType: MsgType;
          let currMsgTime: Date = new Date(message.timestamp);
          let convo: string = "Bot: " + message.text + "\n\n";
          this._appStateService.pushMessageToHistory(convo);
          
          //this.textToSpeechClient(message.text)
              
          // Hide typing message
          this._appStateService.isActionMsgShown = false;

          if (message.attachments && message.channelData) {
            // Process Adaptive Cards
            let dataType = message.channelData.dataType;
            let rawAdaptiveCard = message.attachments;

            if (rawAdaptiveCard[0].content.body) {

              // switch (numDataBlocks) {
              switch (dataType) {
                case "CARD_INFO_RA":
                  // RightAnswer response
                  self.addInfoCardToConversation(rawAdaptiveCard, currMsgTime);
                  break;

                case "CARD_ITSM_INC_NEW":
                case "CARD_ITSM_INC_Existing":
                case "CARD_ITSM_SR_NEW":
                  // Single Incident/SR Card
                  let isNew: boolean = (dataType === "CARD_ITSM_INC_NEW" || dataType === "CARD_ITSM_SR_NEW") ? true : false;
                  let ticketType: TicketType = (dataType === "CARD_ITSM_SR_NEW" ? TicketType.ServiceRequest : TicketType.Incident);
                  let newTicketCard = new NewTktCardModel(rawAdaptiveCard, isNew, ticketType);
                  console.log("newTicketCard: ", newTicketCard);
                  currMsgBody = newTicketCard;
                  currMsgType = MsgType.NewTicketCard;
                  break;

                // case "CARD_ITSM_INC_NEW_AD":
                //   let isNewAD: boolean = (dataType === "CARD_ITSM_INC_NEW_AD" || dataType === "CARD_ITSM_SR_NEW") ? true : false;
                //   let ticketTypeAD: TicketTypeAD = (dataType === "CARD_ITSM_SR_NEW" ? TicketTypeAD.ServiceRequest : TicketTypeAD.Incident);
                //   let newTicketCardAD = new NewTktCardModelAD(rawAdaptiveCard, isNewAD, ticketTypeAD);
                //   console.log("newTicketCard: ", newTicketCardAD);
                //   currMsgBody = newTicketCardAD;
                //   currMsgType = MsgType.NewTicketCardAD;
                //   break;

                case "CARD_ITSM_INC_STATUS":
                case "CARD_ITSM_SR_STATUS":
                  // Status Card
                  let isIncidentCard: boolean = (dataType === "CARD_ITSM_INC_STATUS") ? true : false;
                  currMsgType = MsgType.StatusCardCollection;
                  currMsgBody = self.parseRawToStatusCards(rawAdaptiveCard, isIncidentCard);
                  break;

                case "CARD_ITSM_OUTAGE":
                  // Outage Card
                  currMsgType = MsgType.OutageCard;
                  currMsgBody = new OutageCardModel(rawAdaptiveCard);
                  break;

                // TODO: Change the datatype of this card.
                case "CARD_ITSM_INC_UPDATECLOSE":
                  // Choice Card - with actions
                  currMsgType = MsgType.ChoiceCard;
                  currMsgBody = new ChoiceCardModel(message);
                  break;

                case "CARD_AD":
                  // Form card
                  currMsgType = MsgType.FormCard;
                  currMsgBody = rawAdaptiveCard[0];
                  break;

                case "CARD_FAQ":
                  // FAQ Card - with multiple content types
                  currMsgType = MsgType.FaqCard;
                  currMsgBody = new MultiContentCardModel(rawAdaptiveCard);
                  break;

                default:
                  // Unknown Card
                  currMsgType = MsgType.TextOnly;
                  currMsgBody = "ERROR: " + dataType + " is not yet implemented!! Kindly report to the dev team." +
                    JSON.stringify(rawAdaptiveCard);
              }
            } else {
              currMsgType = MsgType.TextOnly;
              currMsgBody = rawAdaptiveCard[0].content.title;
            }
          } else if ((message.attachments || message.adaptiveCard) && !message.channelData) {
            // Adaptive cards without any channel-data
            // TODO: Remove the temporary solution below.
          
            let rawCard = (message.adaptiveCard) ? message.adaptiveCard : message.attachments[0];
            if(rawCard.contentType=="application/vnd.microsoft.card.adaptive"){
              currMsgType = MsgType.AdaptiveCard;
              this.isAdaptiveCard = true
              this.RenderAdaptiveCard(rawCard.content)
              currMsgBody = ' ';
            }
            else if (rawCard.content.actions) {
              // Action Card
              this.shouldConnectSocket = true;
              currMsgType = MsgType.ActionCard;
              currMsgBody = new ActionCardModel(rawCard);
            } else if (rawCard.contentType === "application/vnd.microsoft.card.video") {
              // Video Card
              if(rawCard.content.type == 1){
                currMsgType = MsgType.YouTubeVideoCard;
                currMsgBody = this.sanitizer.bypassSecurityTrustResourceUrl(rawCard.content.media[0].url);
              } else{
                currMsgType = MsgType.VideoCard;
                currMsgBody = rawCard.content.media[0].url;
              }
            } else {
              // Error Message
              currMsgType = MsgType.TextOnly;
              currMsgBody = "ERROR: Unidentified attachment!! Kindly report to the dev team." +
                JSON.stringify(rawCard);
            }
          } else {
            // Text or Choice response
            if (message.channelData) {
              let dataType = message.channelData.dataType;

              switch (dataType) {
                case "TEXT_FORMATTED":
                  // Formatted Text
                  currMsgType = MsgType.TextFormatted;
                  currMsgBody = new FormattedTextModel(message.text);
                  break;

                case "YES_NO":
                  // Choice Card
                  currMsgType = MsgType.YesNoCard;
                  currMsgBody = new ChoiceCardModel(message);
                  break;

                case "FEEDBACK_START":
                  // Feedback Card
                  currMsgType = MsgType.ThumbsCard;
                  currMsgBody = new ChoiceCardModel(message);
                  break;

                case "OTHER_CHOICE":
                  // Choice Card
                  currMsgType = MsgType.ChoiceCard;
                  currMsgBody = new ChoiceCardModel(message);
                  break;

                case "calendar":
                case "calender":
                  // Calendar Card
                  currMsgType = MsgType.Calender;
                  currMsgBody = message.text;
                  break;

                default:
                  // TODO: Remove this... temporary solution!!! Write an error instead.
                  // Plain Text
                  if (message.channelData.rawData) {
                    self.sysId = message.channelData.rawData;
                  }
                  currMsgType = MsgType.TextOnly;
                  currMsgBody = message.text;
                  break;
              }
            } else {
              // Plain Text
              currMsgType = MsgType.TextOnly;
              currMsgBody = message.text;
            }

            if (self.isCollectingInfoArticles) {
              self.isCollectingInfoArticles = false;
            }
          }

          if (!self.isCollectingInfoArticles) {
            let newMsg = new ChatMessageModel(MsgAuthor.Bot, currMsgBody, currMsgType, currMsgTime);
            self.addMsgToConversation(newMsg);
            if(this.isAdaptiveCard)
            { 
              let time = setTimeout(()=>{
              if( document.getElementById('ad_card_body'))
              {
                let a = document.getElementsByClassName('ad_card_body')
                a[a.length-1].appendChild(this.renderedAdCard);
                this.isAdaptiveCard = false;
                
             clearTimeout(time);
             }
              },1000);
          }
          }
          self.scrollToBottom();
        })
    );
  }


  // NOTE: The text from the text-area is stored in "msgToBot".
  sendMsgToBot() {
    if (!isUndefined(this.msgToBot) && this.msgToBot != '') {
      // self.checkDirectlineConnection();
      if (this._currConnState == ConnectionStatus.Online) {
        if(!this._appStateService.getLiveAgentFlag()){
          self.postMsgToBot(this.msgToBot);
        }
        else{
          console.log("right path")
          this.tempMsg = this.msgToBot
          let msg = this.msgToBot
          this._appStateService.connectWithAgent(this.msgToBot,this.convID,this.memberID,this.jwtFromGenesys).
      subscribe(
        resp => {
          console.log("after post to bot",resp)
          let customMsgId: number;
          let newMsg: ChatMessageModel = new ChatMessageModel(MsgAuthor.User, this.tempMsg, MsgType.TextOnly, new Date());
          customMsgId = this.addMsgToConversation(newMsg);
          self.scrollToBottom();
        },

        error => {
          console.log("firstCall failed",error)
        });
        }
        
      } else {
        console.log("** ERROR: Connection interrupted! Cannot send messages to bot.");
      }

      // Clear the text-area
      this.msgToBot = '';
    }
  }


  clickedfromLeftMenu(msg: string) {
    self.msgToBot = msg;
    self.sendMsgToBot();
  }


  sendChoiceResponse(card: ChoiceCardModel, selectedIdx: number, conversationIdx: number) {
    if (conversationIdx == this.conversation.length - 1) {
      console.log("** selectedChoice: ", card.choices[selectedIdx]);
      if (this._currConnState == ConnectionStatus.Online) {
        if (card.shouldSendAsValue) {
          self.postMsgToBot(card.choices[selectedIdx], false);
        } else {
          self.postMsgToBot(card.choices[selectedIdx]);
        }
        card.markOption(selectedIdx);
      }
    }
  }


  private checkDirectlineConnection() {
    this.subscription.add(
      self.directLineObj.connectionStatus$
        .subscribe(connectionStatus => {
          this._currConnState = connectionStatus;
          console.log("** Connection Status: ", connectionStatus);

          switch (connectionStatus) {
            case ConnectionStatus.Uninitialized:
              // self._modalMsgBody = null;
              this.showModalMsg(1, "Trying to connect...");
              break;

            case ConnectionStatus.Connecting:
              // self._modalMsgBody = null;
              this.showModalMsg(1, "Connecting...");
              break;

            case ConnectionStatus.Online:
              // self._modalMsgBody = null;
              // this.showHideModalMsg(1, "Connected!!");
              this.showModalMsg(1, "Connected!!");
              this.hideModalMsg();
              break;

            case ConnectionStatus.ExpiredToken:
              this.showModalMsg(2, "Bot Token Expired! Please login again.");
              break;

            case ConnectionStatus.FailedToConnect:
              this.showModalMsg(2, "Connection failure! Please login again.");
              break;

            case ConnectionStatus.Ended:
              this.showModalMsg(2, "Session expired! Please login again.");
              break;

            default:
              break;
          }

          console.log("** Connection MSG:\n", self._modalMsgBody);
        })
    );
  }


  private postLoginSuccess() {
    this.directLineObj.postActivity({
      from: { id: self.userName },
      name: 'UserLoginSuccess',
      type: 'event',
      value: ''
    })
      .subscribe((data) => {
        console.log("SUCCESS: Requested for Greeting dialog!");
      });
  }
  
  private postLiveAgentDisconnectSuccess() {
    this.directLineObj.postActivity({
      from: { id: self.userName },
      name: 'DisconnectSuccessful',
      type: 'event',
      value: ''
    })
      .subscribe((data) => {
        console.log("SUCCESS: Live Agent Disconnected");
      });
  }


  private postMsgToBot(userMsg, shouldSendAsValue: boolean = true) {
    // TODO: Re-write the below code to incorporate delivery report in form data sending.
    if (!shouldSendAsValue) {
      // Form submission
      let formPayload = this.createFormPayload(userMsg);
      this.subscription.add(
        self.directLineObj.postActivity(formPayload)
          .subscribe(data => console.log('** Form posted: ', data))
      );
      return;
    }

    // First add the user message to the conversation list, and then POST it.
    let customMsgId: number;
    let newMsg: ChatMessageModel = new ChatMessageModel(MsgAuthor.User, userMsg, MsgType.TextOnly, new Date());
    customMsgId = this.addMsgToConversation(newMsg);
    self.scrollToBottom();

    // POST the message.
    self.directLineObj.postActivity(this.createUserMsgPayload(userMsg, customMsgId))
      .subscribe(message => {
        console.log(`** postMsgToBot Success: ${JSON.stringify(message)}`);

        // Handle resending
        if (!message.includes('|')) {
          // Show resend icons for all pending messages.
          console.log("Retry sending the message!!");
          this.flagPendingMsgsForRetry();
        }
        return;
      },
        error => {
          console.log(`** postMsgToBot ERROR: ${JSON.stringify(error)}`);

          // Acknowledge the message receipt as NOT sent and show retry button.
          // self.postedErrorMsg(usrmsg);
          return;
        });

  }


  flagPendingMsgsForRetry() {
    for (let i in this.ackPendingIdxList) {
      let idx = this.ackPendingIdxList[i];
      this.conversation[idx].flagForResend();
    }
    console.log("** Flagged all pending messages for resend!!");
  }


  resendUserMsg(conversationIdx: number) {
    let resendingMsg: ChatMessageModel = this.conversation.splice(conversationIdx, 1)[0];
    console.log("** Resending the message: ", resendingMsg);

    // Also remove the entry from the ledger
    this._conversationCustomIdLedger.splice(conversationIdx, 1)[0];

    // Send the message
    this.postMsgToBot(resendingMsg.msgBody);
  }


  createUserMsgPayload(msg: string, customMsgId: number) {
    return {
      from: { id: self.userName },
      type: 'message',
      text: msg,
      channelData: {
        'authObj': self.authObj,
        'geoLocation': self.geoLocationObj,
        'jwtToken': self.jwtToken,
        'customUserId': customMsgId,
        'username': this._appStateService.username
      }
    };
  }


  createFormPayload(formData: any) {
    // let displayText = (formData instanceof String) ? formData : "Form submitted!";
    let displayText = (typeof formData === "string") ? formData : "Form submitted!";
    return {
      from: { id: self.userName },
      type: 'message',
      text: displayText,
      value: formData,
      channelData: {
        'authObj': self.authObj,
        'geoLocation': self.geoLocationObj,
        'jwtToken': self.jwtToken,
        'username': this._appStateService.username
      }
    };
  }


  submitFormCard(formData) {
    this.postMsgToBot(formData, false);
  }


  acknowledgeUserMessage(userMsg: any) {
    let conversationIdx: number = userMsg.channelData.customUserId;
    // if (conversationIdx >= this.conversation.length) {
    if (this.ackPendingIdxList.includes(conversationIdx)) {
      console.log("Acknowledgement received!!");
      this.conversation[conversationIdx].acknowledgeMsg();

      // Also remove it from the ackPendingIdxList
      let delIdx = this.ackPendingIdxList.indexOf(conversationIdx);
      this.ackPendingIdxList.splice(delIdx, 1);
    } else {
      console.log("** ATTENTION: Conversation-list is out of sync or message is already acknowledged!!", userMsg);
    }
  }


  // This controls the modal dialog of the input-container.
  showModalMsg(msgType: number, msgBody: string) {
    this._modalMsgBody = msgBody;
    this._shouldShowModalMsg = true;
    this._modalMsgType = msgType;
    this._isTxtDisabled = (msgType == 2);
  }


  hideModalMsg() {
    this._modalMsgBody = null;
    this._shouldShowModalMsg = false;
    this._modalMsgType = -1;
    this._isTxtDisabled = false;
  }


  showHideModalMsg(msgType: number, msgBody: string) {
    this.showModalMsg(msgType, msgBody);
    setTimeout(() => {
      this.hideModalMsg();
    }, 600);
  }


  public toggleBtnVisibility(card: NewTktCardModel) {
    card.isButtonsShown = !card.isButtonsShown;
  }


  public hideCardBtns(card: NewTktCardModel) {
    card.isButtonsShown = false;
  }


  public showCardBtns(event: Event, card: NewTktCardModel) {
    event.stopPropagation();
    card.isButtonsShown = true;
  }


  private addMsgToConversation(newMsg: ChatMessageModel): number {
    let msgCount = this.conversation.length;
    let customId: number;
    let lastMsg: ChatMessageModel;

    if (msgCount > 0) {
      lastMsg = this.conversation[msgCount - 1];
      // Check if last message avatar needs to be hidden.
      if (lastMsg.author == newMsg.author) {
        lastMsg.isAvatarShown = false;
      }
    }

    // Make last message react to the new message.
    if (this.reactPendingIdxList.length > 0 && newMsg.author == MsgAuthor.User) {
      for (let i in this.reactPendingIdxList) {
        let convIdx = this.reactPendingIdxList[i];
        console.log(`** ConvIdx: ${convIdx}`);

        if (typeof this.conversation[convIdx].msgBody.reactToNewMessage === 'function') {
          this.conversation[convIdx].msgBody.reactToNewMessage(newMsg);
        } else if (this.chkArrEleCanReact(this.conversation[convIdx].msgBody)) {
          for (let msgItem of this.conversation[convIdx].msgBody) {
            msgItem.reactToNewMessage(newMsg);
          }
        }
      }
      this.reactPendingIdxList = [];
    }

    // Handle pending acknowledgement messages.
    if (newMsg.isAckPending) {
      this.ackPendingIdxList.push(this.conversation.length);
    }

    // Add new message to conversation list & generate CustomID.
    customId = this.conversation.push(newMsg) - 1;

    // Add new message to react-pending list
    if (typeof newMsg.msgBody.reactToNewMessage === 'function' || this.chkArrEleCanReact(newMsg.msgBody)) {
      this.reactPendingIdxList.push(customId);
    }

    // Push CustomID to ledger
    if (newMsg.author == MsgAuthor.User) {
      this._conversationCustomIdLedger.push(customId);
    } else {
      // There is no use of customID for messages from bot.
      this._conversationCustomIdLedger.push(-1);
    }

    // Set focus on TextArea
    this.focusOnTA();

    return customId;
  }


  private chkArrEleCanReact(msgBody: any) {
    const chkResult = Array.isArray(msgBody) && (msgBody.length > 0) && (typeof msgBody[0].reactToNewMessage === 'function');
    console.log('[chkArrEleCanReact] msgBody: ', msgBody);
    console.log('[chkArrEleCanReact] chkResult: ', chkResult);
    return chkResult;
  }


  private scrollToBottom() {
    clearTimeout(this._scrollTimer);
    this._scrollTimer = setTimeout(function () {
      let div2 = document.getElementsByClassName("bottom-rise-enabler-2")[0];
      if (div2) {
        div2.scrollTop = div2.scrollHeight;
      }
    }, 200);
  }


  private focusOnTA() {
    document.getElementById("textareaMsg").focus();
  }


  // NOTE: rawData is the "attachments" in the original response.
  private parseRawToStatusCards(rawData: any, isIncident: boolean): Array<TktStatusCardModel> {
    // text contains '----'
    let dataList: Array<any> = rawData[0].content.body;
    let blockLength: number = 5;
    if(isIncident){
      blockLength = 6;
    }
    let itCount = (dataList.length - 1) / blockLength;
    let baseIdx: number;
    let newStatusCard: TktStatusCardModel;
    let isIncidentCard: boolean = isIncident;
    let cardSet: Array<any> = []
    // tktNumber: string, desc: string, assignGroup: string, lastUpdate: string, status: number;
    let statusCards: Array<TktStatusCardModel> = [];
    for (let i = 0; i < itCount; i++) {
      baseIdx = (i * blockLength) + 1;
      cardSet = dataList.slice(baseIdx, baseIdx+blockLength);
      console.log("Card Set " + baseIdx + ":", cardSet);
      // tktNumber = dataList[baseIdx].text.split(':')[1].trim();
      // desc = dataList[baseIdx + 1].text.split(':')[1].trim();
      // status = dataList[baseIdx + 2].text.split(':')[1].trim();
      // baseIdx = i * blockLength;
      // tktNumber = dataList[baseIdx].text.split(':')[1].trim();
      // desc = dataList[baseIdx + 1].text.split(":")[1].trim();
      
      // tktNumber = dataList[baseIdx].text;
      // desc = dataList[baseIdx + 1].text;
      // assignGroup = dataList[baseIdx + 2].text.split(":")[1].trim();
      // lastUpdate = dataList[baseIdx + 3].text;
      // status = dataList[baseIdx + 4].text.split(":")[1].trim();
      newStatusCard = new TktStatusCardModel(cardSet, isIncidentCard);
      statusCards.push(newStatusCard);
    }

    return statusCards;
  }


  private addInfoCardToConversation(rawData: any, msgTime: Date) {
    // Parse raw into info-card model
    let newInfoCard = new InfoCardModel(rawData);

    if (self.isCollectingInfoArticles) {
      // Add the new item to last item of conversation
      self.conversation[self.conversation.length - 1].msgBody.push(newInfoCard);
    } else {
      // Create a new entry in conversation
      let newMsg = new ChatMessageModel(MsgAuthor.Bot, [newInfoCard], MsgType.InfoCardCollection, msgTime);
      self.addMsgToConversation(newMsg);
      self.isCollectingInfoArticles = true; // Reset when next text message is received.
    }
  }


  private refreshToken() {
    console.log("** Refreshing DirectLine token!");
    self._directLine.refreshDirectLineToken(self.directLineToken);
  }


  private toggleCardGroupState(conversationIdx: number) {
    this.conversation[conversationIdx].isExpanded = !this.conversation[conversationIdx].isExpanded;
  }


  // Handle Calendar
  date: Date = new Date();

  settings = {
    bigBanner: false,
    timePicker: false,
    format: 'dd-MM-yyyy',
    defaultOpen: true,
    // closeOnSelect: true
  }

  public onDateSelect(dateInput: any) {
    if (self.sendCalDate) {
      self.directLineObj.postActivity({
        from: { id: self.userName },
        type: 'message',
        text: new String(self.date).slice(0, 15),
        channelData: { 'authObj': self.authObj, 'geoLocation': self.geoLocationObj, 'jwtToken': self.jwtToken }
      }).subscribe(function (message: any) {
        console.log(`** postMsgToBot Success: ${JSON.stringify(message)}`);
        return;
      }, function (error: any) {
        console.log(`** Error in posting: ${JSON.stringify(error)}`);
        return;
      });
      self.sendCalDate = false;
    }
    else {
      self.date = new Date();
    }
  }


  addNote() {
    self.msgToBot = "Add Note";
    self.sendMsgToBot();
  }


  public openIframe(url: string) {
    this._appStateService.urlIframe = url;
    this._appStateService.isIframeShown = true;
  }


  public openPopup(url: string) {
    window.open(url, 'popup', 'width=900,height=600');
    return false;
  }

  public scheduleCall(msg: string){
    self.postMsgToBot(msg);
    this.shouldConnectSocket = false;
  }

  public connectSocket(msg: string){
    //let resp = this._appStateService.fetchToken()
    // if(this.shouldConnectSocket){
    //   this._appStateService.getIntent(self.userName).
    //   subscribe(
    //     resp => {
    //       this._appStateService.fetchToken(self.userName, self.email, resp[0].usecase).
    //         subscribe(
    //           resp => {
    //             console.log("Connection Initiated", resp)
    //             this._appStateService.setAuthResponse(resp)
    //             this.afterTokenFetched(resp)
    //           },
      
    //           error => {
    //             console.log("firstCall failed",error)
    //           });
    //     },
  
    //       error => {
    //         console.log("intentCall failed",error)
    //       });
    //   this.shouldConnectSocket = false;
    // }
  
    self.postMsgToBot(msg);
    this.shouldConnectSocket = false;
  }

  afterTokenFetched(resp:any){
    let response = resp;
    this.memberID = response.member.id;
    this.convID = response.id;
    this.wssUrl = response.eventStreamUri;
    this.jwtFromGenesys = response.jwt;
    console.log("---->",this.memberID, this.convID, this.wssUrl, this.jwtFromGenesys)
    // this._appStateService.setLiveAgentFlag(true)
    this._appStateService.webSocketConnection(response)
  }

  msgFromAgentRecvd(msg:any){
    let currMsgBody: any;
    let currMsgType: MsgType;
    let currMsgTime: Date = new Date("2020-08-20T04:35:31.454Z")
    currMsgType = MsgType.TextOnly;
    currMsgBody = msg;
    let newMsg = new ChatMessageModel(MsgAuthor.Bot, currMsgBody, currMsgType, currMsgTime);
    self.addMsgToConversation(newMsg);
    self.scrollToBottom();
    console.log("at chatBox",msg)
  }
  agentLeftAlert(val:any){
    let currMsgBody: any;
    let currMsgType: MsgType;
    let currMsgTime: Date = new Date("2020-08-20T04:35:31.454Z")
    currMsgType = MsgType.LiveAgentConnectionAlert;
    currMsgBody = val;
    let newMsg = new ChatMessageModel(MsgAuthor.Bot, "You are now disconnected.", currMsgType, currMsgTime);
    self.addMsgToConversation(newMsg);
    this.postLiveAgentDisconnectSuccess()
    self.scrollToBottom();
  }

  showAgentConnectedMsg(msg:any){
    let currMsgBody: any;
    let currMsgType: MsgType;
    let currMsgTime: Date = new Date("2020-08-20T04:35:31.454Z")
    currMsgType = MsgType.LiveAgentConnectionAlert;
    currMsgBody = msg;
    let newMsg = new ChatMessageModel(MsgAuthor.Bot, msg, currMsgType, currMsgTime);
    self.addMsgToConversation(newMsg);
    self.scrollToBottom();
  }

  showAgentDetailsMsg(msg:any){
    let currMsgBody: any;
    let currMsgType: MsgType;
    let currMsgTime: Date = new Date()
    currMsgType = MsgType.TextOnly;
    currMsgBody = msg.text;
    let newMsg = new ChatMessageModel(MsgAuthor.Bot, msg, currMsgType, currMsgTime);
    self.addMsgToConversation(newMsg);
    self.scrollToBottom();
  }


  //Toggle Listen Mode
  toggleListenMode() {
    (!this.isListening) ? this.listenUserSpeech() : this.stopListenUserSpeech();
  }

  textToSpeechClient(msg:any){

    let wordBoundaryList = [];
    let speechConfig = SpeechSDK.SpeechConfig.fromSubscription(this.voiceSubscriptionDetails["subscriptionKey"], this.voiceSubscriptionDetails["serviceRegion"]);
    let player = new SpeechSDK.SpeakerAudioDestination();

    player.onAudioEnd = function (_) {
      console.log("playback finished");
      wordBoundaryList = [];
    };

    var audioConfig  = SpeechSDK.AudioConfig.fromSpeakerOutput(player);

    speechConfig.speechRecognitionLanguage = this.voiceSubscriptionDetails["speechRecognitionLanguage"];

    let synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig, audioConfig);

            // The event synthesizing signals that a synthesized audio chunk is received.
        // You will receive one or more synthesizing events as a speech phrase is synthesized.
        // You can use this callback to streaming receive the synthesized audio.
        synthesizer.synthesizing = function (s, e) {
          console.log(e);
          console.log("(synthesizing) Reason: " + SpeechSDK.ResultReason[e.result.reason] +
                  "Audio chunk length: " + e.result.audioData.byteLength);
        };

        // The synthesis started event signals that the synthesis is started.
        synthesizer.synthesisStarted = function (s, e) {
          console.log(e);
          console.log("(synthesis started)")
        };

        // The event synthesis completed signals that the synthesis is completed.
        synthesizer.synthesisCompleted = function (s, e) {
          console.log(e);
          console.log("(synthesized)  Reason: " + SpeechSDK.ResultReason[e.result.reason] +
                  " Audio length: " + e.result.audioData.byteLength);
          this.textToSpeechmsg='';
        };

        // The event signals that the service has stopped processing speech.
        // This can happen when an error is encountered.
        synthesizer.SynthesisCanceled = function (s, e) {
          const cancellationDetails = SpeechSDK.CancellationDetails.fromResult(e.result);
          let str = "(cancel) Reason: " + SpeechSDK.CancellationReason[cancellationDetails.reason];
          if (cancellationDetails.reason === SpeechSDK.CancellationReason.Error) {
            str += ": " + e.result.errorDetails;
          }
          console.log("reason: "+e.result.reason);
            
        };

        // This event signals that word boundary is received. This indicates the audio boundary of each word.
        // The unit of e.audioOffset is tick (1 tick = 100 nanoseconds), divide by 10,000 to convert to milliseconds.
        synthesizer.wordBoundary = function (s, e) {
          window.console.log(e);
          console.log("(WordBoundary), Text: " + e.text + ", Audio offset: " + e.audioOffset / 10000 + "ms.");
          wordBoundaryList.push(e);
        };

        const complete_cb = function (result) {
          if (result.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted) {
            console.log("synthesis finished");
          } else if (result.reason === SpeechSDK.ResultReason.Canceled) {
            console.log("synthesis failed. Error detail: " + result.errorDetails);
          }
          console.log("complete_cb: "+result);
          synthesizer.close();
          synthesizer = undefined;
        };

        const err_cb = function (err) {
          console.log(err);
          synthesizer.close();
          synthesizer = undefined;
        };

          synthesizer.speakTextAsync(msg,
            complete_cb,
            err_cb); 
        

  }

  listenUserSpeech() {
    let previousTextContent = (self.msgToBot) ? self.msgToBot : '';
    let statusDiv = "";
    
    let audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
    let speechConfig = SpeechSDK.SpeechConfig.fromSubscription(this.voiceSubscriptionDetails["subscriptionKey"], this.voiceSubscriptionDetails["serviceRegion"]);
    
    speechConfig.speechRecognitionLanguage = this.voiceSubscriptionDetails["speechRecognitionLanguage"];
    speechConfig.endpointId = this.voiceSubscriptionDetails["endpointId"];

    this.isListening = true;
    this.restartMicTimer();
    console.log("listening");

    self.reco = new SpeechSDK.SpeechRecognizer(speechConfig, audioConfig);
   // console.log(JSON.stringify(this.reco));

    //  The event recognizing signals that an intermediate recognition result is received.
    self.reco.recognizing = (s, e) => {
      this.restartMicTimer();
      console.log('recognizing text', e.result.text);
      this.msgToBot = previousTextContent + ' ' + e.result.text;
    };

    //  The event recognized signals that a final recognition result is received.
    self.reco.recognized = (s, e) => {
      this.restartMicTimer();
      console.log('recognized text', e.result.text);

      // Indicates that recognizable speech was not detected, and that recognition is done.
      if (e.result.reason === SpeechSDK.ResultReason.NoMatch) {
        var noMatchDetail = SpeechSDK.NoMatchDetails.fromResult(e.result);
        statusDiv += "(recognized)  Reason: " + SpeechSDK.ResultReason[e.result.reason] + " NoMatchReason: " + SpeechSDK.NoMatchReason[noMatchDetail.reason] + "\r\n";
        console.log(statusDiv);
      } else {
        statusDiv += "(recognized)  Reason: " + SpeechSDK.ResultReason[e.result.reason] + " Text: " + e.result.text + "\r\n";
        console.log(statusDiv);
        //previousTextContent += ' ' + e.result.text;
        this.msgToBot = e.result.text;
        this.sendMsgToBot()
        //this.textToSpeechClient(this.msgToBot);
      }
    };

    self.reco.canceled = function (s, e) {
      window.console.log(e);

      statusDiv += "(cancel) Reason: " + SpeechSDK.CancellationReason[e.reason];
      if (e.reason === SpeechSDK.CancellationReason.Error) {
        statusDiv += ": " + e.errorDetails;
        console.log(statusDiv);
      }
      statusDiv += "\r\n";
    };

    // Signals that a new session has started with the speech service
    self.reco.sessionStarted = function (s, e) {
      window.console.log(e);
      statusDiv += "(sessionStarted) SessionId: " + e.sessionId + "\r\n";
      console.log(statusDiv);
    };

    // Signals the end of a session with the speech service.
    self.reco.sessionStopped = function (s, e) {
      window.console.log(e);
      statusDiv += "(sessionStopped) SessionId: " + e.sessionId + "\r\n";
      this.isListening = false;
    };

    // Signals that the speech service has started to detect speech.
    self.reco.speechStartDetected = function (s, e) {
      window.console.log(e);
      statusDiv += "(speechStartDetected) SessionId: " + e.sessionId + "\r\n";
      console.log(statusDiv);
    };

    // Signals that the speech service has detected that speech has stopped.
    self.reco.speechEndDetected = function (s, e) {
      window.console.log(e);
      statusDiv += "(speechEndDetected) SessionId: " + e.sessionId + "\r\n";
      console.log(statusDiv);
    };

    // Starts recognition
    self.reco.startContinuousRecognitionAsync();
  }


  stopListenUserSpeech() {
    this.isListening = false;
    if (self.reco) {
      self.reco.stopContinuousRecognitionAsync(
        function () {
          self.reco.close();
          self.reco = undefined;
        },
        function (err) {
          self.reco.close();
          self.reco = undefined;
        });
    }
  }


  restartMicTimer(){
    clearTimeout(this._micTimer);
    this._micTimer = setTimeout(() =>  {
      this.stopListenUserSpeech();
    }, 4000);
  }

  // sanitizeUrl(url: string){
  //   return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  // }


  public sendStatusCardMsg(tktObj: TktStatusCardModel){
    const msg = `Yes, show status of ${tktObj.tktNumber}`;
    this.postMsgToBot(msg);
  }


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _directLine: DirectLineService,
    private _sharedService: SharedService,
    public _appStateService: AppStateService,
    private _i18nService: I18nService,
    private sanitizer:DomSanitizer
  ) {
    this.i18nTexts = this._i18nService.getLocaleTextsForComponent(this.constructor.name);
    this.subscriptionFromAgent = this._appStateService.getMessageFromAgent().subscribe(message => { this.msgFromAgentRecvd(message) });
    this.alertFromAgentLeave = this._appStateService.getAgentLeftAlert().subscribe(message => { this.agentLeftAlert(message) });
    this.alertFromAgentJoin = this._appStateService.getAgentJoinedAlert().subscribe(message => { this.showAgentConnectedMsg(message) });
    this.alertFromAgentDetails = this._appStateService.getAgentQueueDetails().subscribe(message => { this.showAgentDetailsMsg(message) });
    this._appStateService.setLiveAgentFlag(false)
  }


  ngOnInit() {
    self = this;
    let sub = this.route
      .queryParams
      .subscribe(params => {
        self.fetchParamsData(params);
      });

    // TODO: Pass the conversation_id alongwith the secret, to restore the interrupted conversation.
    // TODO: For token, pass streamUrl
    this.directLineObj = this._directLine.createDirectlineConnection(self.directLineToken);
    this._appStateService.dlObj = this.directLineObj;
    this._sharedService.userClickedfromLeftmenu = this.clickedfromLeftMenu;
    this.checkDirectlineConnection();
    this.subscribeMessages();
    console.log("Voice: ", this._appStateService.getVoiceSubscriptionDetails());
    this.voiceSubscriptionDetails = this._appStateService.getVoiceSubscriptionDetails();

    // Post login success
    if (this._appStateService.shouldShowGreeting) {
      this.postLoginSuccess();
      this._appStateService.shouldShowGreeting = false;
    }

    // let customMsgId: number
    // let newMsg: ChatMessageModel = new ChatMessageModel(MsgAuthor.Bot, "Hi "+ self.userName +", this is *|b_ IVA_b|*, How may I help you today?", MsgType.TextOnly, new Date());
    // customMsgId = this.addMsgToConversation(newMsg);
    self.scrollToBottom();  

    // this.hideModalMsg();
    this.focusOnTA();

    // Set timer to refresh token after every 25mins.
    this._timerTokenRefresh = setInterval(() => {
      self.refreshToken();
    }, 1500000);
  }


  ngOnDestroy() {
    window.clearInterval(this._timerTokenRefresh);
    this.subscription.unsubscribe();
    this.subscriptionFromAgent.unsubscribe();
    this.alertFromAgentLeave.unsubscribe()
    this.alertFromAgentJoin.unsubscribe()
    this.alertFromAgentDetails.unsubscribe()
  }

}
