// This is the model for a generic action card

export class ActionCardModel {

    public textBlocks: Array<string> = [];
    public actionChoices: Array<ActionItemModel> = [];


    parseAndInit(rawdata: any){
        let body: Array<any> = rawdata.content.body;
        let actions: Array<any> = rawdata.content.actions;

        // parse the body
        this.textBlocks = body.map( textObj => {
            return textObj.text;
        } );

        // parse the actions
        this.actionChoices = actions.map( actionObj => {
            let iframeTarget = (actionObj.openIframe) ? true : false ;
            if(actionObj.url == 'chat'){
                return new ActionItemModel(actionObj.title, actionObj.url, false,true, false);
            }
            else if(actionObj.url == 'call'){
                return new ActionItemModel(actionObj.title, actionObj.url, false,false, true);
            }
            else{
                return new ActionItemModel(actionObj.title, actionObj.url, iframeTarget,false, false);
            }
        } );
    }


    constructor(data: any){
        // The data expected is the rawAdaptiveCard[0]
        this.parseAndInit(data);
    }
}


export class ActionItemModel {
    constructor(
        public title: string,
        public url: string,
        public shouldOpenIframe: boolean,
        public shouldConnectSocket: boolean,
        public shouldScheduleCall: boolean
        // public type: ActionType
    ){}
}
