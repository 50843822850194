import { Injectable } from '@angular/core';
import { Observable, fromEvent, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DirectLine } from './botframework-directlinejs';


@Injectable({
  providedIn: 'root'
})
export class AppStateService {

  // Global values
  public username: string = "** Dev **";
  
  public resizeWidth$: Observable<any>;
  public resizeHeight$: Observable<any>;
  public currHeight: number;
  public currWidth: number;
  public readonly mobileWidth: number = 900;
  public shouldShowGreeting: boolean = false;
  public isIframeShown: boolean = false;
  public urlIframe: string = "https://dev69569.service-now.com/com.glideapp.servicecatalog_cat_item_view.do?v=1&sysparm_id=6370412e3771300054b6a3549dbe5d82&sysparm_link_parent=5d643c6a3771300054b6a3549dbe5db0&sysparm_catalog=undefined&sysparm_catalog_view=catalog_default&sysparm_view=catalog_default";
  public isActionMsgShown: boolean = false;
  public voiceSubscriptionDetails: any;
  public response: any;
  
  
  // public agentName: string;
  public conn:WebSocket;
  private msgFromAgent = new Subject<any>();
  private agentLeft = new Subject<any>();
  private agentJoined = new Subject<any>();
  private agentQueueDetails = new Subject<any>();
  public liveAgentFlag :boolean = false;
  public agentAvailable :boolean = false;
  public liveAgentID: string;
  
  public conversationHistory: Array<String> = [];

  public dlObj: DirectLine;


  private monitorWinResize(){
    // Observe Width
    this.resizeWidth$ = fromEvent(window, 'resize')
      .debounceTime(200)
      .map(() => window.innerWidth)
      .distinctUntilChanged()
      .startWith(window.innerWidth);

    this.resizeWidth$.subscribe(
      data => {
        this.currWidth = data;
        console.log("** Width resized", data);
      }
    );

    // Observe Height
    this.resizeHeight$ = fromEvent(window, 'resize')
      .debounceTime(200)
      .map(() => window.innerHeight)
      .distinctUntilChanged()
      .startWith(window.innerHeight);

    this.resizeHeight$.subscribe(
      data => {
        this.currHeight = data;
        console.log("** Height resized", data);
      }
    );
  }
  
  public pushMessageToHistory(message:any){
    this.conversationHistory.push(message);
  }

  
  public fetchToken(userName:any, email: any, usecase: any){
    let webUrl = "https://api.mypurecloud.ie/api/v2/webchat/guest/conversations";
    // const headers = new Headers({
      
    //   'Content-Type': 'application/json'
    //   // 'Authorization' : 'Bearer ' + environment.tokenGenesys
    // });
    const options = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    let data = {
      "organizationId": "69ad5769-f309-4c7d-9998-c1b03dbd34af",
      "deploymentId" : "6307605f-e52f-4128-b9d3-a2cd9c37cdcd",
      "routingTarget" : {
        "targetType" : "queue",
        "targetAddress": "InnoHub_ CustomerB_AllOther_Chat"
      },
      "memberInfo" : { 
        "displayName" : userName + "",
        "avatarImageUrl": "http://some-url.com/JoeDirtsFace",
        "lastName" : "Joe",
        "firstName" : "Dirt",
        "phoneNumber" : "+12223334444",
        "customFields" : {
          "queueId" : "13c0b58a-ba82-4e96-b9bd-b32b3d22d59a",
          "usecase" : usecase,
          "skill" : "English",
          "email" : email + ""
        }
      }
    }
    
    return this.http.post(webUrl, data, options);
  }

  getIntent(userName:any){
    console.log("---->inside intent call",userName)
    let webUrl = 'https://tcs-cdd-prd-weu-was-core-001.azurewebsites.net/api/v2.0/admin/report/usecase/getPrevious';
    const options = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    let data ={
      "userId": userName
  }
    return this.http.post(webUrl, JSON.stringify(data), options);
  }

  connectWithAgent(msg:any,convID:any,memberID:any,token:any){
    console.log("---->inside call",msg,convID,memberID,token)
    let webUrl = 'https://api.mypurecloud.ie/api/v2/webchat/guest/conversations/'+convID+'/members/'+memberID+'/messages';
    // const headers = new Headers({
      
    //   'Content-Type': 'application/json',
    //   'Authorization' : 'Bearer ' + token 
    // });
    const options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization' : 'Bearer ' + token 
      }
    };
    let data ={
      "body" : "USER:" + msg,
      "bodyType" : "standard"
    }
 
    // const options = new RequestOptions({ method: RequestMethod.Post, headers: headers });
    return this.http.post(webUrl, JSON.stringify(data), options);
  }

  webSocketConnection(resp){
    this.conn = new WebSocket(resp.eventStreamUri);
    this.conn.onopen = () => {
      console.log('connected');
      // this.getAgentDetails().subscribe(
      // data => {
      //   console.log("Agent Details:", data)
      //   this.liveAgentID = data['entities'][3].id;
      //   this.agentQueueDetails.next("Please wait while we connect you to our live agent.")
      //   // if(data['entities'][3].displayName){
      //   //   this.agentName = data['entities'][3].displayName;
      //   //   this.agentQueueDetails.next("Please wait while we connect you to " + this.agentName)
      //   // }
      //   // else{
      //   //   this.agentQueueDetails.next("Please wait while we connect you to our live agent.")
      //   // }
      //        },

      // err => {
      //   console.log("Get Agent Details request failed", err)
      // });
      this.conn.send("connected")
  };
 
  this.conn.onmessage = (data) => {
    if(data.data.topicName != "channel.metadata"){
      console.log("websocket message:", data.data);
    }
      
      let dataFinal = JSON.parse(data.data);
      if(dataFinal.topicName != "channel.metadata"){
        //Straight away disconnect the websocket connection if no agent is available in queue
        if(!this.agentAvailable){
          if(dataFinal.eventBody.body && dataFinal.eventBody.bodyType == "notice"){
            this.agentQueueDetails.next(dataFinal.eventBody.body);
            this.conn.close();
            this.setLiveAgentFlag(false);
          }
          else if(dataFinal.eventBody.body && dataFinal.eventBody.body == "No agents Available, Disconnecting the chat."){
            this.agentQueueDetails.next("No agents available right now. Please try again after some time")
            this.conn.close();
            this.setLiveAgentFlag(false);
          }
          else if(dataFinal.eventBody.body && dataFinal.eventBody.body == "We are now transferring you to the available agent."){
            this.agentQueueDetails.next("Please wait while we connect you to our live agent")
            this.agentAvailable = true;
          }
        }
        //get the agent ID on ALERTING message
        if(dataFinal.eventBody.member && dataFinal.eventBody.member.state && dataFinal.eventBody.member.state == 'ALERTING'){
          this.liveAgentID = dataFinal.eventBody.member.id;
        }
        if(this.liveAgentID != undefined){
          if(dataFinal.eventBody.bodyType && dataFinal.eventBody.sender.id == this.liveAgentID && dataFinal.eventBody.bodyType == 'member-join'){
            this.setLiveAgentFlag(true);
            this.getAgentDetails().subscribe(
              data => {
                console.log("Agent Details after call pickup:", data)
                if(data['entities'][3].displayName){
                  this.agentJoined.next("You are now connected with " + data['entities'][3].displayName + ".")
                }
                else{
                  this.agentJoined.next("You are now connected with our live agent.")
                }
              },
        
              err => {
                console.log("Get Agent Details request failed", err)
              });
              let resp = this.getAuthResponse()
              console.log("Auth Response: ", resp)
              this.connectWithAgent(this.conversationHistory,resp.id,resp.member.id,resp.jwt).
          subscribe(
            resp => {
              console.log("after post to bot",resp)
              this.conversationHistory=[];
              },
    
            error => {
              console.log("firstCall failed",error)
            });
          }
          else if(dataFinal.eventBody.bodyType && dataFinal.eventBody.sender.id == this.liveAgentID && dataFinal.eventBody.bodyType == 'member-leave'){
            this.setLiveAgentFlag(false);
            this.agentLeft.next(true);
            this.conn.close();
          }
          else if(dataFinal.eventBody.body && dataFinal.eventBody.bodyType == "standard"){
            console.log("--->msg from agent",dataFinal.eventBody);
            if(!dataFinal.eventBody.body.startsWith("USER:")){
              this.msgFromAgent.next( dataFinal.eventBody.body );
            }
          }
        }
      }


      // if(dataFinal.metadata.type == 'member-change'){
        // this.countMemberJoin = this.countMemberJoin + 1
      //   if(data['eventBody'].member.id == this.liveAgentID){
      //     if(!this.getLiveAgentFlag() && data['eventBody'].member.state == 'CONNECTED'){
      //       this.getAgentDetails().subscribe(
      //         data => {
      //           console.log("Agent Details after call pickup:", data)
      //           this.liveAgentID = data['entities'][3].id;
                // if(data['entities'][3].displayName){
                //   this.agentName = data['entities'][3].displayName;
                // }
      //                },
        
      //         err => {
      //           console.log("Get Agent Details request failed", err)
      //         });
              // if(this.agentName){
              //   this.agentJoined.next("You are now connected with " + this.agentName + ".")
              // }
              // else{
              //   this.agentJoined.next("You are now connected with our live agent.")
              // }
      //         let resp = this.getAuthResponse()
      //         console.log("Auth Response: ", resp)
      //         this.connectWithAgent(this.conversationHistory,resp.id,resp.member.id,resp.jwt).
      //     subscribe(
      //       resp => {
      //         console.log("after post to bot",resp)
      //         this.conversationHistory=[];
      //         },
    
      //       error => {
      //         console.log("firstCall failed",error)
      //       });
      //     }
      //     else if(this.getLiveAgentFlag() && data['eventBody'].member.state == 'DISCONNECTED'){
      //       this.setLiveAgentFlag(false);
      //       this.agentLeft.next(true);
      //     }
      //   }
      // }
      // else if(dataFinal.metadata.type == 'message' && dataFinal.eventBody.hasOwnProperty('body')){
      //   console.log("--->msg from agent",dataFinal.eventBody);
      //   if(!dataFinal.eventBody.body.startsWith("USER:")){
      //     this.msgFromAgent.next( dataFinal.eventBody.body );
      //   }
      // }
      
      // if(dataFinal.eventBody.hasOwnProperty('body')){
      //   console.log("--->body",dataFinal.eventBody)
      //   if(dataFinal.eventBody.bodyType == "standard"){
      //     console.log("---msg from agent",dataFinal.eventBody.body)
      //     if(!dataFinal.eventBody.body.startsWith("USER:")){
      //       this.msgFromAgent.next( dataFinal.eventBody.body );
      //     }
      //   }
      //   else if(dataFinal.eventBody.bodyType == "member-leave"){
      //     this.countMemberLeave = this.countMemberLeave + 1
      //     if(this.countMemberLeave == 2){
      //       this.setLiveAgentFlag(false)
      //       this.agentLeft.next(true)
      //     }
          
      //   }
        
      // }
      
  }
  }

  getAgentDetails(){
    let resp = this.getAuthResponse()
    const url = 'https://api.mypurecloud.ie/api/v2/webchat/guest/conversations/' + resp.id + '/members';
    const opt = {
      'headers': {
        'Content-Type': 'application/json',
      // 'Access-Control-Allow-Origin': '*',
        'Authorization' : 'Bearer ' + resp.jwt 
      },
    };
    return this.http.get(url, opt);
  }

  // getAgentName(obj) {
  //   if(obj.entities.length > 2){
  //     if(obj.entities[2].displayName){
  //       return obj.entities[2].displayName;
  //     }
  //     else return "John Sarthak";
  //   }
  //   else return "John Sarthak";
  // }

  public setVoiceSubscriptionDetails(data: any){
    this.voiceSubscriptionDetails=data["voiceSubscription"];
  }

	public getVoiceSubscriptionDetails(){
    return this.voiceSubscriptionDetails;
  }
  
  getMessageFromAgent(): Observable<any> {
    return this.msgFromAgent.asObservable();
  }

  getAgentLeftAlert(): Observable<any>{
    return this.agentLeft.asObservable();
  }

  getAgentJoinedAlert():Observable<any>{
    return this.agentJoined.asObservable();
  }

  getAgentQueueDetails():Observable<any>{
    return this.agentQueueDetails.asObservable();
  }

  setLiveAgentFlag(val:boolean){
    this.liveAgentFlag = val
  }

  getLiveAgentFlag(){
    return this.liveAgentFlag
  }
  
  setAuthResponse(val:any){
    this.response = val
  }

  getAuthResponse(){
    return this.response
  }

  
  constructor(
    private http: HttpClient
  ) {
    this.monitorWinResize();
  }
}