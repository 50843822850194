import { ChatMessageModel } from "./chat-message-model";

export class TktStatusCardModel {
    public tktNumber: string;
    public statusText: string;
    public assignGroup: string;
    public isIncidentCard: boolean;
    public shortDesc: string = '';
    public lastUpdate: string = '';
    public commentFlag: boolean = false;
    public comment: string = '';

    // public statusText: string;
    // private _ticketStates = {
    //     1: "New",
    //     2: "In Progress",
    //     3: "On Hold",
    //     6: "Resolved",
    //     7: "Closed",
    //     8: "Cancelled"
    // };

    // resolveTktState() {
    //     this.statusText = this._ticketStates[this.stateNum];
    // }

    private mapRawToModel(dataList: any){
        if(this.isIncidentCard){
            this.tktNumber = dataList[0].text;
            this.shortDesc = dataList[1].text.split(":")[1].trim();
            this.assignGroup = dataList[2].text.split(":")[1].trim();
            this.lastUpdate = dataList[3].text;
            this.statusText = dataList[4].text.split(":")[1].trim();
            this.comment = dataList[5].text;
            if(this.comment !== ""){
                this.commentFlag = true;
            }
        }
        else{
            this.tktNumber = dataList[0].text;
            this.shortDesc = dataList[1].text.split(":")[1].trim();
            this.lastUpdate = dataList[2].text;
            this.statusText = dataList[3].text.split(":")[1].trim();
        }
    }

    constructor(
        cardSet: any, isIncident: boolean
    ) {
        this.isIncidentCard = isIncident;
        this.mapRawToModel(cardSet);
        // if (isNaN(parseInt(status))) {
        //     this.statusText = stateNum.toString();
        // } else {
        //     this.resolveTktState();
        // }
    }
}
