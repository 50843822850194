import { ChatMessageModel } from "./chat-message-model";

export class NewTktCardModel {

    public tktNumber: string;
    public tktState: string;
    public tktTypeStr: string;
    public isNewIncCard: boolean;
    public shortDesc: string = '';
    public longDesc: string = '';
    public tktCategory: string = '';
    public tktPriority: string = '';
    public assignGroup: string;
    public usrPhone: string = '';
    public stateNum: number | string;
    public usrEmail: string = '';
    public usrName: string = '';
    public lastUpdated: string = '';
    public approval: string = '';
    public priority:string = '';
    public isButtonsShown: boolean = true;
    public isMinimalCard: boolean = false;
    private _ticketStates = {
        1: "New",
        2: "In Progress",
        3: "On Hold",
        6: "Resolved",
        7: "Closed",
        8: "Cancelled"
    };
    private tktType: TicketType;

    resolveTktState() {
        this.tktState = this._ticketStates[this.stateNum];
    }


    private mapRawToModel(rawData: any){
        let dataList: Array<any> = rawData[0].content.body;

        switch (this.tktType) {
            case TicketType.Incident:
                this.tktTypeStr = "Incident";
                this.tktNumber = dataList[1].text.split(':')[1].trim();
                this.stateNum = dataList[2].text.split(':')[1].trim();
                if (isNaN(parseInt(dataList[2].text.split(':')[1].trim()))) {
                    this.tktState = this.stateNum.toString();
                } else {
                    this.resolveTktState();
                }
                // this.tktState = dataList[2].text.split(':')[1].trim();
                this.shortDesc = this.removeAttrName(this.getAttrFromObj(dataList[3], "text"));
                this.tktCategory = this.removeAttrName(this.getAttrFromObj(dataList[4], "text"));
                this.tktPriority = this.removeAttrName(this.getAttrFromObj(dataList[5], "text"));
                this.assignGroup = this.removeAttrName(this.getAttrFromObj(dataList[6], "text"));
                // this.usrPhone = this.removeAttrName(this.getAttrFromObj(dataList[6], "text"));
                // this.usrEmail = this.removeAttrName(this.getAttrFromObj(dataList[7], "text"));
                break;
        
            case TicketType.ServiceRequest:
                this.isMinimalCard = true;
                this.tktTypeStr = "Service Request";
                this.tktNumber = dataList[1].text;
                this.shortDesc = this.removeAttrName(dataList[2].text);
                this.lastUpdated = this.removeAttrName(dataList[3].text);
                this.tktState = this.removeAttrName(dataList[4].text);
                // this.stage = this.removeAttrName(dataList[4].text);
                // this.approval = this.removeAttrName(dataList[5].text);
                // this.tktPriority = this.removeAttrName(dataList[6].text);
                break;

            default:
                this.tktNumber = "Unrecognized Ticket!!"
                break;
        }
    }


    // Written to handle cases when obj is undefined.
    private getAttrFromObj(obj: any, attrName: string): any{
        if(!obj){
            return '';
        }else{
            return obj[attrName];
        }
    }


    // Removes the leading string before the first ':'
    private removeAttrName(str: string): string{
        if(!str || str.trim()==''){
            // Not a valid attr value
            return '-';
        }
        let arrTemp: Array<any>;
        arrTemp = str.split(':');
        arrTemp.splice(0,1);
        return arrTemp.join(':');
    }


    public reactToNewMessage(newMsg: ChatMessageModel){
        this.isButtonsShown = false;
    }
    

    // NOTE: rawData should only contain the "attachments" object from the original response.
    // "attachments" encapsulates the adaptive card schema.
    constructor(rawData: any, isNew: boolean, tktType: TicketType){
        this.isNewIncCard = isNew;
        this.tktType = tktType;
        this.mapRawToModel(rawData);
    }
}


export enum TicketType{
    Incident,
    ServiceRequest,
};
