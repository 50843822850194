import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Http, RequestOptions, Headers, RequestMethod } from '@angular/http';

@Injectable()
export class SharedService {
  dataArray: string[] = [];
  private userClickedAction = new Subject<string>();
  userClickedAction$ = this.userClickedAction.asObservable();
  userClickedfromLeftmenu: Function;

  constructor(private http: Http) { }

  public userClicked(data: any) {
    this.userClickedAction.next(data);
  }


  public addNote(notes: string, sysId : string) {
    console.log("addNote : ",notes);
    //let webUrl = "http://172.21.192.85:3001/apifile/fileUpload";
    let webUrl = "https://csd-core-1.azurewebsites.net/api/notes";
    const headers = new Headers({
      'Content-Type': 'application/json;  charset=UTF-8',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*'
    });
    let data ={
      "tenantId": "2",
      "sysId": sysId,
      "payload": notes
      
    }

    const options = new RequestOptions({ method: RequestMethod.Post, headers: headers });
    return this.http.post(webUrl, JSON.stringify(data), options);
  }
}